<template>
  <ul>
    <template v-if="selectedProject._id === '5ee04b4b9dccd000d8e4703c'">
      <li class="contact-section">
        <div class="section-title flex-row align-center">
          <div class="section-icon is-flex align-center">
            <font-awesome-layers class="fa-lg">
              <font-awesome-icon icon="info-circle"></font-awesome-icon>
            </font-awesome-layers>
          </div>
          <span class="has-text-weight-bold">{{ $t('contact.title.info') }}</span>
        </div>
        <div class="section-content">
          <p>Geo Science Co., Ltd</p>
        </div>
      </li>
      <li class="contact-section">
        <div class="section-title flex-row align-center">
          <div class="section-icon is-flex align-center">
            <font-awesome-layers class="fa-lg">
              <font-awesome-icon icon="map-marker-alt"></font-awesome-icon>
            </font-awesome-layers>
          </div>
          <span class="has-text-weight-bold">{{ $t('contact.title.address') }}</span>
        </div>
        <div class="section-content">
          <p>163 Trung Kinh St., Yen Hoa Ward, Cau Giay District, Hanoi</p>
        </div>
      </li>
      <li class="contact-section">
        <div class="section-title flex-row align-center">
          <div class="section-icon is-flex align-center rotate-quarter">
            <font-awesome-layers class="fa-lg">
              <font-awesome-icon icon="phone"></font-awesome-icon>
            </font-awesome-layers>
          </div>
          <span class="has-text-weight-bold">{{ $t('contact.title.phone') }}</span>
        </div>
        <div class="section-content">
          <div class="flex-row">
            <a>024.32123110</a><span class="seperator">/</span><a>0985021350</a>
          </div>
        </div>
      </li>
      <li class="contact-section">
        <div class="section-title flex-row align-center">
          <div class="section-icon is-flex align-center">
            <font-awesome-layers class="fa-lg">
              <font-awesome-icon icon="envelope"></font-awesome-icon>
            </font-awesome-layers>
          </div>
          <span class="has-text-weight-bold">{{ $t('contact.title.email') }}</span>
        </div>
        <div class="section-content">
          <a href="mailto:sales@geo-science.com.vn">sales@geo-science.com.vn</a>
        </div>
      </li>
    </template>
    <template v-else>
      <li class="contact-section">
        <div class="section-title flex-row align-center">
          <div class="section-icon is-flex align-center">
            <font-awesome-layers class="fa-lg">
              <font-awesome-icon icon="info-circle"></font-awesome-icon>
            </font-awesome-layers>
          </div>
          <span class="has-text-weight-bold">{{ $t('contact.title.info') }}</span>
        </div>
        <div class="section-content">
          <p>{{ $t('contact.company') }}</p>
        </div>
      </li>
      <li class="contact-section">
        <div class="section-title flex-row align-center">
          <div class="section-icon is-flex align-center">
            <font-awesome-layers class="fa-lg">
              <font-awesome-icon icon="map-marker-alt"></font-awesome-icon>
            </font-awesome-layers>
          </div>
          <span class="has-text-weight-bold">{{ $t('contact.title.address') }}</span>
        </div>
        <div class="section-content">
          <p>{{ $t('contact.address1') }}</p>
        </div>
      </li>
      <li class="contact-section">
        <div class="section-title flex-row align-center">
          <div class="section-icon is-flex align-center rotate-quarter">
            <font-awesome-layers class="fa-lg">
              <font-awesome-icon icon="phone"></font-awesome-icon>
            </font-awesome-layers>
          </div>
          <span class="has-text-weight-bold">{{ $t('contact.title.phone') }}</span>
        </div>
        <div class="section-content">
          <div class="flex-row">
            <a href="tel:+886-2-26481050">+886-2-26481050</a>
            <span class="seperator">/</span>
            <a href="tel:+886-918883451">+886-918883451</a>
          </div>
        </div>
      </li>
      <li class="contact-section">
        <div class="section-title flex-row align-center">
          <div class="section-icon is-flex align-center">
            <font-awesome-layers class="fa-lg">
              <font-awesome-icon icon="envelope"></font-awesome-icon>
            </font-awesome-layers>
          </div>
          <span class="has-text-weight-bold">{{ $t('contact.title.email') }}</span>
        </div>
        <div class="section-content">
          <a href="mailto:geosupply.tw@gmail.com">geosupply.tw@gmail.com</a>
        </div>
      </li>
    </template>
  </ul>
</template>

<script>
export default {
  computed: {
    selectedProject() {
      return this.$store.getters['project/selectedProject'];
    },
  },
};
</script>

<style lang="stylus" scoped>
.rotate-quarter
  transform rotate(0.25turn)
.seperator
  margin 0 0.5rem
.contact-section
  .section-title
    .section-icon
      margin-right 1rem
  .section-content
    padding 1rem 2rem 2rem
</style>
